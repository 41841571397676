
      @use "sass:math";
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.SubNavigation {
  position: relative;
  z-index: 1;
}

.SubNavigationIcon {
  position: relative;
  display: flex;
  margin-right: spacing(0.6);
  &,
  svg {
    max-width: 20px;
    max-height: 20px;
    line-height: 1 !important;
  }

  svg,
  svg g {
    stroke: $color-primary !important;
    fill: $color-primary !important;
  }
}

.SubNavigationButton {
  display: flex;
  align-items: center;
  width: 100%;
  & span {
    display: flex;
    align-items: center;
    svg {
      color: $color-primary;
      margin-right: spacing(1);
    }
  }
  // color: $color-primary;
  .fixed & {
    color: $color-black;
  }

  .highlight & {
    color: $color-primary;
  }
  @media (hover: hover) {
    &:hover + .SubNavigationLinks {
      display: block;
    }
  }
}

.SubNavigationLinks {
  display: none;
  background-color: $color-offWhite;
  min-width: 100%;
  border-radius: $radius-small;
  padding: spacing(1);
  margin: 0;
  white-space: nowrap;
  &:hover,
  &.open {
    display: block;
  }

  a span { // Inner link
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  @include mq($breakpoint-tablet) {
    position: absolute;
    // min-width: 190px;
    box-shadow: shadow(1);
    a {
      padding: spacing(0.5) spacing(0.5);
      margin: 0;
    }
  }
}
