
      @use "sass:math";
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Layout {
  overflow: hidden;
  // scroll-snap-type: y proximity;
}

.DashboardLayout {
  display: flex;
  min-height: 100%;
  flex-grow: 1;
  overflow: hidden;
}

.Content {
  // scroll-snap-type: y proximity;
  margin-top: spacing(2);
  flex: 1 1 auto;
}


.LinksGrid {
  display: flex;
  flex-wrap: wrap;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-weight: 500;
    margin-bottom: spacing(1);

    &:not(:last-child) {
      margin-right: spacing(1);
    } 


    .Icon {
      margin-right: spacing(1);
      // svg {
      //   fill: $color-primary;
      // }
    }
  }
}

@keyframes fadeIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}