
      @use "sass:math";
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
// .Type {}

.common {
  min-height: spacing(2);
}

.displayLarge {
  font-weight: bold;
  font-family: $font-body;
  font-size: spacing(4);
  line-height: spacing(4.5);

  @include mq($breakpoint-tablet) {
    font-size: spacing(5); // 64
    line-height: spacing(6); // 80
  }

  @include mq($breakpoint-tabletLandscape) {
    font-size: spacing(6); // 64
    line-height: spacing(7); // 80
  }

  // @include mq($breakpoint-desktop) {
  //   font-size: spacing(12); // 96
  //   line-height: spacing(13); 
  // }
}

.display {
  font-weight: bold;
  font-family: $font-body;
  font-size: spacing(4);
  line-height: spacing(4.5);

  @include mq($breakpoint-tablet) {
    font-size: spacing(5); // 64
    line-height: spacing(6); // 80
  }

  @include mq($breakpoint-tabletLandscape) {
    font-size: spacing(6); // 64
    line-height: spacing(7); // 80
  }
}

.displayMedium {
  font-weight: bold;
  font-family: $font-body;
  font-size: spacing(3);
  line-height: spacing(3.5);

  @include mq($breakpoint-tablet) {
    font-size: spacing(4); 
    line-height: spacing(5);
  }

  @include mq($breakpoint-tabletLandscape) {
    font-size: spacing(5); 
    line-height: spacing(6);
  }
}

.titleSmall {
  font-family: $font-body;
  font-size: spacing(3); // 34
  line-height: spacing(4); // 40
  font-weight: 500;

  @include mq($breakpoint-desktop) {
    font-size: spacing(4); // 34
    line-height: spacing(5); // 40
    font-weight: 500;
  }
}

.title {
  font-family: $font-body;
  font-size: spacing(3); // 34
  line-height: spacing(3.5); // 40
  font-weight: 500;

  @include mq($breakpoint-desktop) {
    font-size: spacing(3.5); // 34
    line-height: spacing(4); // 40
    font-weight: 500;
  }

  @include mq($breakpoint-desktopMedium) {
    font-size: spacing(4); // 34
    line-height: spacing(4.5); // 40
    font-weight: 500;
  }
}

.titleMedium {
  font-family: $font-body;
  font-size: spacing(4);
  line-height: spacing(4.5);
  font-weight: 500;

  @include mq($breakpoint-tablet) {
    font-size: spacing(4.5);
    line-height: spacing(5);
  }

  @include mq($breakpoint-desktop) {
    font-size: spacing(6);
    line-height: spacing(6.5);
  }
}

.titleLarge {
  font-family: $font-body;
  font-size: spacing(4);
  line-height: spacing(5);
  font-weight: 500;

  @include mq($breakpoint-tablet) {
    font-size: spacing(5.5);
    line-height: spacing(6.5);
  }

  @include mq($breakpoint-desktop) {
    font-size: spacing(7);
    line-height: spacing(8);
  }
}

.subtitle {
  font-size: spacing(2);
  line-height: spacing(2.5);

  font-weight: 500;
  font-family: $font-body;

  @include mq($breakpoint-mobileLarge) {
    font-size: spacing(2.5);
    line-height: spacing(3);
  }

  @include mq($breakpoint-desktop) {
    font-size: spacing(4); // 48
    line-height: spacing(5); // 56
  }

  @include mq($breakpoint-desktopLarge) {
    font-size: spacing(5);
    line-height: spacing(6);
  }
}

.menu {
  font-weight: 500;
  font-size: spacing(2); // 16
  line-height: spacing(3); // 24
}

.base {
  font-size: spacing(2.25);
  line-height: spacing(3.25);
}

.baseMedium {
  font-size: spacing(2.25);
  line-height: spacing(3.25);

  @include mq($breakpoint-tablet) {
    font-size: spacing(3);
    line-height: spacing(3.25);
  }

  @include mq($breakpoint-desktop) {
    font-size: spacing(2.8);
    line-height: spacing(3.2);
  }
}

.baseLarge {
  font-size: spacing(3);
  line-height: spacing(3.5);

  @include mq($breakpoint-tablet) {
    font-size: spacing(3.5);
    line-height: spacing(4);
  }

  @include mq($breakpoint-desktop) {
    font-size: spacing(3.5);
    line-height: spacing(4);
  }
}

.baseSmall {
  font-size: spacing(2); // 14
  line-height: spacing(2.6); // 16
}

.small {
  font-size: spacing(1.8); // 14
  line-height: spacing(2.2); // 16
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

.demi {
  font-weight: 500;
}

.heavy {
  font-weight: 600;
}

.padded {
  padding-bottom: spacing(0.8);
}

// Modifiers

.tight {
  line-height: 1;
}
