
      @use "sass:math";
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.OverlayWrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($color-black, 0.2);
  z-index: 100;
}

.Overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: $color-black;
  background-color: $color-white;
  padding: spacing(2);
  animation: overlay-fade-in $duration-shortest $easing-standard forwards;
}

.smallScreenOnly {
  @include mq($breakpoint-tabletLandscape) {
    display: none;
  }
}

.OverlayHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: spacing(1);
}

.OverlayClose {
  margin-left: auto;
  background: none;
  border: 0;
  cursor: pointer;
}
.OverlayClose:hover,
.OverlayClose:focus,
.OverlayClose:active {
  opacity: 0.6;
}

@keyframes overlay-fade-in {
  0% {
    opacity: 0;
    display: none;
    transform: translateX(100%);
  }
  1% {
    display: block;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
