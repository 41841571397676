
      @use "sass:math";
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Wrapper {
  position: absolute;
  right: 0;
  margin-left: auto;
  max-width: 320px;
}

.Overlay {
  height: 100%;
}

.MobileNavigation {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $color-white;
}

.Branding {
  display: block;
  position: relative;
  width: 280px;
  height: 60px;
  overflow: hidden;
  margin: 0 0 spacing(4);
  padding: 0 spacing(1.5);
  & a {
    display: block;
  }
}

.NavigationIcon {
  margin-right: spacing(1);
  svg {
    stroke: $color-primary;
    fill: $color-primary;
  }
}

.Nav {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-bottom: spacing(2);
}

.NavLinks {
  display: flex;
  flex-direction: column;
  margin-bottom: spacing(3);
  text-align: left;
  a,
  button {
    text-align: left;
    padding: spacing(1.5) spacing(0.5);
    border-bottom: 2px solid $color-grey95;
    text-decoration: none;
    border-radius: $radius-small;
    span {
      font-size: spacing(2.5);
    }
  }
}
