
      @use "sass:math";
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Container {
  width: 100%;
  clear: both;
}

.noClearfix {
  clear: none;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.gutter {
  padding-left: spacing(2);
  padding-right: spacing(2);

  @include mq($breakpoint-tablet) {
    padding-left: spacing(3);
    padding-right: spacing(3);
  }

  @include mq($breakpoint-desktopLarge) {
    padding-left: spacing(3);
    padding-right: spacing(3);
  }
}

// SIZES
.spacious {
  margin-top: spacing(8);
  // margin-bottom: spacing(2);
  @include mq($breakpoint-tablet) {
    margin-top: spacing(12.5);
    // margin-bottom: spacing(2);
  }
}

.full {
  width: 100%;
}

.wide {
  max-width: spacing(180);
}

.large {
  max-width: spacing(150);
}

.mediumLarge {
  max-width: spacing(140);
}

.medium {
  max-width: spacing(120);
}

.small {
  max-width: spacing(52);
  @include mq($breakpoint-tablet) {
    max-width: spacing(60);
  }
}

.hideOverflow {
  overflow: hidden;
}
