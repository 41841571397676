
      @use "sass:math";
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Header,
.HeaderWrapper,
.Navigation {
  z-index: 100;
}

.NavigationInner,
.Header {
  background-color: $color-white;
}

.Navigation {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  scroll-snap-align: start;
  width: calc(100% - 16px);
  margin: 0 auto;
  @include mq($breakpoint-tablet) {
    width: calc(100% - 32px);
  }
}

.HeaderWrapper {
  margin: 0 8px;
  @include mq($breakpoint-tablet) {
    margin: 0 16px;
  }
}

.Header {
  padding-top: spacing(1);
  padding-bottom: spacing(1);
  display: none;
  align-items: flex-start;
  padding:spacing(1) 16px;
  border-bottom: 2px solid $color-grey95;

  @include mq($breakpoint-tablet) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.HeaderItem {
  display: flex;
  padding: spacing(1);
  border-radius: $radius-small;
  text-decoration: none;
  max-width: 500px;

  span {
    display: flex;
    flex-direction: column;
    // font-weight: 600;
    margin-left: spacing(1);
  }

  svg {
    margin: spacing(0.5);
    fill: $color-primary;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $color-white-secondary;
  }

  @include mq($breakpoint-tabletLandscape) {
    margin-left: spacing(4);
  }
}

.HeaderDetail {
  user-select: all;
  font-weight: 500;
}



.NavigationInner {
  display: flex;
  align-items: center;
  // overflow: hidden;
  border-bottom: 2px solid $color-grey95;
  border-radius: 0 0 $radius-small $radius-small;
  transition: padding $duration-short $easing-standard;
  padding:spacing(0.5) 16px;

}

// Branding

.HeaderBranding {
  width: 200px;
  height: 44px;
  margin-right: spacing(2);
  display: none;

  @include mq($breakpoint-tabletLandscape) {
    display: block;
  }
}

.HeaderLinks {
  display: flex;
}

.Branding {
  font-size: inherit;
  display: block;
  position: relative;
  width: 65px;
  height: 32px;
  overflow: hidden;
  margin-right: spacing(2);

  & a,
  & button {
    display: block;
  }

  &:hover {
    transform: scale(1.05);
    transition: $duration-shortest $easing-standard;
  }
}

.Logo {
  transform: translateX(0px);
  transition: $duration-short $easing-standard;
  img {
    border-radius: 0;
  }
}

// Regular link

.NavLink {
  position: relative;
  display: block;
  padding: spacing(0.5) spacing(1.5);
  margin-right: spacing(0.5);
  color: $color-black;
  text-decoration: none;
  border-radius: $radius-small;

  span {
    display: block;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $color-white-secondary;
  }

  &.outline,
  &.highlight {
    display: flex;
    align-items: center;

    background-color: $color-black;
    border-radius: $radius-small;
    color: $color-white;
    padding: 0 spacing(2.5);
    margin-left: spacing(1);
    &:hover {
      background-color: $color-black-secondary;
      color: $color-white;
      transition-duration: $duration-shortest;
    }
  }

  &.outline {
    background-color: $color-white;
    border: 2px solid $color-black;
    color: $color-black;
    &:hover {
      border-color: $color-black-secondary;
      transition-duration: $duration-shortest;
    }
  }
}

.MainNav {
  display: flex;
  align-items: center;
  transition: $duration-short $easing-standard;
  will-change: transform;
}

.UserNav {
  display: flex;
  font-weight: 500;
  // margin-left: auto;
}

// Nav toggle

.ToggleNavButton {
  display: none;
}

.ToggleNavButton {
  margin-left: spacing(1.5);
  &:hover {
    svg path {
      fill: $color-black-secondary;
      stroke: $color-black-secondary;
    }
  }
}

// User

.UserButton {
  display: block;
  padding: spacing(0.75) spacing(1);
  background-color: $color-white;
  color: $color-black;
  text-decoration: none;
  svg {
    fill: $color-primary;
  }

  &:hover,
  &:focus {
    background-color: $color-white-secondary;
    transition-duration: $duration-shortest;
  }
}

.UserIcon {
  display: block;
}

.hideBranding {
  .Branding {
    display: block;
    animation: expand $duration-short $easing-standard forwards;
  }
}

.fixed {

  .NavigationInner {
    padding-top: spacing(1);
    padding-bottom: spacing(1);
    transition: padding $duration-short $easing-standard;
    @include mq(null, $breakpoint-tablet) {
      padding-top: spacing(0.5);
      padding-bottom: spacing(0.5);
    }
  }


  .NavigationInner {
    border-bottom: 0;
  }

  .Logo {
    @include mq($breakpoint-tabletLandscape) {
      will-change: transform;
      transform: translateX(-90px);
      transition: $duration-short $easing-standard;
    }
  }
  .MainNav {
    @include mq($breakpoint-tabletLandscape) {
      will-change: transform;
      transform: translateX(-90px);
      transition: $duration-short $easing-standard;
    }
  }
}

@include mq($breakpoint-tablet) {
  .hideBranding {
    .Branding {
      display: none;
    }
  }
}

.UserNavList {
  right: 0;
  position: absolute;
}

a.Bookings.Bookings {
  margin-left: auto ;
  display: block;
  padding-top: spacing(0.3);
  padding-bottom: spacing(0.3);
  margin-right: spacing(0.5);
  &:active {
    transform: scale(0.99);
    transition: all $duration-standard $easing-standard;
    background-color: $color-secondary-red;
    border: 2px solid $color-secondary-red;
    color: $color-white;
  }
}

// Mobile only styles
@include mq(null, 850px) {
  .ToggleNavButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: spacing(1) 0 spacing(1);
  }

  .MainNav {
    display: none;
  }


  .UserNav {
    display: none;
  }
}

@keyframes expand {
  0% {
    display: flex;
  }
  1% {
    transform: translateX(-120%);
  }
  100% {
    transform: translateX(0);
  }
}
