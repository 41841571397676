
      @use "sass:math";
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Footer {
  position: relative;
  display: flex;
  align-content: center;
  overflow: hidden;
  color: $color-black;
  transition: $duration-shortest;
  margin-top: auto;
  padding-top: spacing(6);
  a:hover {
    color: $color-primary;
    transition: $duration-shortest;
  }
}

.Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: spacing(3);
  padding-bottom: spacing(1);
  & > * + * {
    margin-top: spacing(2);
  }
  @include mq($breakpoint-mobileLandscape) {
    padding-top: spacing(4);
    padding-bottom: spacing(1);
  }
}

.Branding {
  transition: $duration-short $easing-standard;
}

.Logo {
  position: relative;
  display: block;
  transition: $duration-shortest $easing-standard;
  width: 200px;
  height: 44px;
  &:hover {
    transform: scale(1.02);
    transition: $duration-shortest $easing-standard;
  }
}

.FooterLinks {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  
  a {
    text-decoration: none;
    margin-bottom: 5px;
    margin-right: 10px;
    @include mq($breakpoint-mobileLandscape) {
      margin: 0 spacing(1);
    }
  }
}

.SocialWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: spacing(2);
  border-bottom: 1px solid $color-primary;
  h2 {
    color: $color-primary;
  }
}

.LinkWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: spacing(6);
  font-size: 0.9em;
  line-height: 0.9;
  text-align: center;
}

.Social {
  display: flex;
  align-items: center;
  flex-direction: row;
  color: $color-white;
  margin-bottom: spacing(1);

  a,
  button {
    margin-right: spacing(1);
    display: inline-flex;
    align-items: center;

    &:hover,
    &:focus {
      transform: scale(1.03);
      transition: $easing-standard $duration-short;
    }
  }

  svg {
    fill: $color-primary;
  }
}

.Copyright {
  text-align: left;
  font-size: 1rem;
  color: $color-black;
  word-break: keep-all;
}
