
      @use "sass:math";
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.IconButton {
  vertical-align: middle;
  white-space: nowrap;
  border-radius: $radius-small;
  padding: spacing(1);
  &:focus,
  &:hover {
    text-decoration: none;
    outline: 0;
  }

  &.withChildren {
    padding: spacing(0.5) spacing(1.5);
  }

  &:disabled {
    background-color: $color-grey95;
    color: $color-grey50;
    cursor: not-allowed;
    svg {
      fill: $color-grey50;
    }
  }
}

.IconButtonInner {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    vertical-align: top;

    .withChildren & {
      margin-right: spacing(0.5);
    }
  }
}

.primary {
  background-color: $color-white;
  &:hover,
  &:focus {
    background-color: $color-white-secondary;
  }
}

.secondary {
  background-color: $color-white;
  // border: 2px solid $color-primary;
  transition: $easing-standard $duration-short;

  svg {
    fill: $color-primary;
  }

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled) {
    transform: scale(1.01);
    transition: $easing-standard $duration-short;
  }
}

.IconButtonText {
  margin-left: spacing(0.5);
}

.rounded {
  border-radius: 50%;
}

.solid {
  color: $color-white;
  background-color: $color-primary;

  &:focus,
  &:hover,
  &:not(:disabled) {
    color: $color-white;
    background-color: $color-primary-red;
  }
  &:active {
    background-color: $color-primary-red;
  }
}

.increaseHitArea {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: spacing(-1);
    right: spacing(-1);
    bottom: spacing(-1);
    left: spacing(-1);
  }
}
