
      @use "sass:math";
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
// Regular link

.NavigationLink {
  position: relative;
  display: block;
  padding: spacing(0.5) spacing(1.5);
  color: $color-black;
  text-decoration: none;
  border-radius: $radius-small;
  transition: $duration-short $easing-standard;

  span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: $duration-short $easing-standard;
  }

  & span svg {
    fill: $color-primary;
    stroke: $color-primary;
    transition: $duration-short $easing-standard;
  }

  &:hover,
  &:active,
  &:focus,
  &.active {
    background-color: $color-white-secondary;
    transition: $duration-short $easing-standard;
  }

  &:not(:last-of-type) {
    margin-right: spacing(0.5);
  }

  &.outline,
  &.highlight {
    display: flex;
    align-items: center;

    background-color: $color-primary;
    border-radius: $radius-small;

    padding: 0 spacing(2.5);
    margin-left: spacing(1);

    span {
      color: $color-white;
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      transform: scale(1.02);
    }

    & svg span {
      fill: $color-primary !important;
      stroke: $color-primary !important;
    }
  }

  &.outline {
    background-color: $color-white;
    border: 2px solid $color-black;
    color: $color-black;
    &:hover {
      border-color: $color-black-secondary;
    }
  }

  &.secondary {
    background-color: $color-white;
    border: 2px solid $color-primary;

    span {
      color: $color-primary;
    }

    & span svg {
      fill: $color-primary;
    }

    &:hover,
    &:active,
    &:focus,
    &.active {
      background-color: $color-primary;
      span {
        color: $color-white;
      }
      & span svg {
        fill: $color-white;
      }
    }
    &:active,
    &.active {
      transform: scale(0.99);
      transition: all $duration-standard $easing-standard;
      background-color: $color-secondary-red;
      border: 2px solid $color-secondary-red;
      color: $color-white;
    }
  }
}
